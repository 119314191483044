import axios from "axios"
import { useEffect, useState } from "react"

const CombinationTrendFirstHeaders = ({ gender, category, searchParams, setSearchParams }) => {

  const [headers, setHeaders] = useState([])


  const getTrends = async (signal) => {
    try {
      const resp = await axios.get(`/social-trends-v2-api/combination-trend-count/${searchParams.get('trends') == 'social' ? 'social' : 'retailer'}?gender=${gender || 'Female'}&category=${searchParams.get('category').split('&').join('%26') || 'Dresses'}&geography=${searchParams.get('geography') || 'All'}&source=${searchParams.get('source') || ''}&time=${searchParams.get('time') || '30 days'}`, { signal })
      let allCount = 0
      resp.data.trendCounts.forEach(d => { allCount = allCount + d.count; })
      setHeaders([{ value: 'All', count: allCount }, ...(resp.data.trendCounts.sort((a, b) => (b.count - a.count)))])
    } catch (e) {
      if (e.code != 'ERR_CANCELED') {
        console.error(e)
      }
    }
  }

  useEffect(() => {
    if (headers.length > 0) {
      setSearchParams(prev => {
        //        prev.set('primary-header', headers[0].value)
        prev.delete('primary-header')
        return prev
      })
    }
  }, [headers])

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      getTrends(signal)
    } catch (e) {
      console.log(e)
    }
    return () => {
      abortController.abort()
    }
  }, [searchParams.get('trends'), searchParams.get('gender'), searchParams.get('category'), searchParams.get('time'), searchParams.get('geography'), searchParams.get('source')])

  return <div className="flex flex-wrap px-10">
    {
      headers.map(header => <button
        className="p-0.5 px-1 m-0.5 rounded border-slate-700 border-2 bg-white text-black disabled:bg-slate-200 disabled:border-slate-400 text-[22px] "
        disabled={(header.value == 'All' && !searchParams.get('primary-header')) || (header.value == searchParams.get('primary-header'))}
        onClick={() => {
          setSearchParams(prev => {
            if (header.value == 'All')
              prev.delete('primary-header')
            else
              prev.set('primary-header', header.value)
            return prev;
          })
        }}
      >{header.value}({header.count})</button>)
    }

  </div>
}


export default CombinationTrendFirstHeaders

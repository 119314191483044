import axios from "axios";
import { useEffect, useState } from "react"; import { FaAngleRight, FaArrowCircleUp } from "react-icons/fa";
import ModalImage from "react-modal-image";
import Loadingwrapper from "../../component/LoadingWrapper";
import Popup from "../../component/Popup";
import { indexedPostUrl } from "../../utility/helper_functions";
import CombinationTrendImages from "./CombinationTrendImages";
import RecipeRanking from "./RecipeRanking";
import TrendLifeCycleTooptip from "./TrendLifeCycleTooltip";


const CombinationTrends = ({ gender, category, searchParams, setSearchParams, setFilteredImages }) => {

  const [selectedTrendId, setSelectedTrendId] = useState(null);
  const [trends, setTrends] = useState([]);
  const [loading, setLoading] = useState(null);
  const [trendCount, setTrendCount] = useState(0);
  const [page, setPage] = useState(1);

  const getTrends = async (signal) => {
    setLoading(true)
    if (page == 1) {
      setTrends([])
    }
    try {
      const resp = await axios.get(`/social-trends-v2-api/combination-trends/${searchParams.get('trends') == 'retailer' ? 'retailer' : 'social'}?gender=${gender || 'Female'}&category=${searchParams.get('category').split('&').join('%26') || 'Dresses'}&geography=${searchParams.get('geography') || 'All'}&source=${searchParams.get('source') || ''}&time=${searchParams.get('time') || '30 days'}&sort=${searchParams.get('sort')}&page=${page || 1}&limit=${searchParams.get('limit') || 50}${searchParams.get('primary-header') && searchParams.get('primary-header') != 'All' ? `&primary-header=${searchParams.get('primary-header')}` : ''}`, { signal })
      setLoading(false)
      setTrendCount(resp.data.count)
      setFilteredImages(resp.data.imageCount)
      if (searchParams.get('trends') == 'retailer') {
        resp.data.trends.forEach(trend => {
          trend.images = trend.images.map(prod => ({ mediaId: prod.pk, postUrl: prod.product_url, username: prod.brand, imageUrl: prod.image_url, title: prod.title }))
        })
      }
      if (page == 1) {
        setTrends(resp.data.trends)
      } else {
        setTrends(prev => ([...prev, ...resp.data.trends]))
      }
    } catch (e) {
      if (e.code != 'ERR_CANCELED') {
        console.error(e)
      }
    }
  }


  useEffect(() => {
    setPage(1)
  }, [searchParams])

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      getTrends(signal)
    } catch (e) {
      console.log(e)
    }
    return () => {
      abortController.abort()
    }
  }, [searchParams, page])

  return <div className="">
    <div className="flex flex-col py-2  text-sm mt-2">
      <div className="w-full flex flex-wrap justify-center mt-2">
        {
          trends && trends.length > 0 ?
            trends.map((trend, i) => <div className={`w-1/2 shrink-0 h-96 p-2 odd:border-r-[1.5px] border-slate-700 border-b-[1.5px]`} key={`${trend.tag}_${trend.value} `} >
              <div className="w-full h-8 flex justify-between px-6 overflow-x-hidden overflow-clip">
                <p className="text-xl my-1 shrink-0 font-medium flex">{trend.tags.map(t => (`${t.value}`)).join(', ')}
                  {false ? <RecipeRanking trendId={trend._id} searchParams={searchParams} /> : null}
                </p>
                <TrendLifeCycleTooptip trend={trend} trendstype={searchParams.get('trends')} />
                <p className="h-4 shrink-0 mx-2 text-xs ">Count : {trend.imageCount}</p>
                <p className="shrink-0 text-xs">Trend Rank <span className="font-bold">{i + 1} of {trendCount}</span></p>
              </div>
              <div className="relative w-full h-0" >
                <button className=" absolute right-0 h-60 top-4 px-1 hover:bg-slate-100-holder rounded-l bg-slate-300/80 hover:bg-slate-100/75 py-4 z-10" onClick={() => {
                  setSelectedTrendId(trend._id)
                }}>
                  <FaAngleRight size={50} color='#666' />
                </button>
              </div>
              <div className="h-96 flex overflow-x-hidden w-full shrink-0">
                {
                  trend.images && trend.images.map(img => <div key={img.postUrl} className="p-1 min-w-fit">

                    <ModalImage src={img.imageUrl} className=' h-64 my-1' small={img.imageUrl} large={img.imageUrl} hideZoom={false} hideDownload={true} /> {/*
                    <img className="h-64 my-1" src={img.imageUrl} />
                  */}
                    <a
                      href={indexedPostUrl(img.postUrl, img.mediaId)}
                      target='_blank'
                      className="text-sm text-blue-500 hover:text-blue-400 hover:underline">{img.username}</a>
                    {img.timeStamp && <div>{new Date(img.timeStamp).toLocaleDateString()}</div>}
                    {img.title && <div className="flex text-xs ">{img.title.slice(0, 20)}</div>}
                  </div>)
                }
              </div>
            </div>)
            : <div className="w-full my-20">
              {!loading && <p className="w-full text-center text-lg font-semibold">No trends Found</p>}
            </div>
        }
        <Loadingwrapper loading={loading}>
        </Loadingwrapper>
        <div className="flex justify-center py-3 w-full shrink-0">
          {!loading
            && (page * 50) < trendCount
            && <button className="text-white bg-blue-500 active:bg-blue-700 hover:bg-blue-600 rounded px-4 py-1 text-lg font-semibold " disabled={loading || page * parseInt(searchParams.get('limit')) >= trendCount} onClick={() => {
              setPage(prev => prev + 1)
            }}>Load More</button>}
        </div>
      </div>
    </div>

    <div className="fixed right-4 bottom-4 w-10 h-10 z-20">
      <button className="w-full h-full rounded-full bg-white"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" })
        }}
      ><FaArrowCircleUp
          size={40}
          color='#444444'
          stroke="#fff"
        /></button>

    </div>
    <Popup isPopUp={selectedTrendId != null} setIsPopUp={(next) => {
      if (!next) {
        setSelectedTrendId(null)
      }
    }}>
      <CombinationTrendImages trendId={selectedTrendId} gender={gender} searchParams={searchParams} />

    </Popup>
  </div >
}

export default CombinationTrends

import { useParams } from "react-router-dom"
import CombinationTrendPage from "../DesignTrendLifeCycles/CombinationTrendPage"


const CombinationTrendPageWrapper = () => {

  const { type, id } = useParams()
  return <div className="px-3 py-4">
    <CombinationTrendPage trendId={id} type={type} />
  </div>

}

export default CombinationTrendPageWrapper

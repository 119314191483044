import React, { useEffect, useState, } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Swal from 'sweetalert2';

const TrendDisplay = () => {
  const navigate = useNavigate();
  const { userLoggedIn } = useSelector(state => state)
  const [insertfilenames, setInsertFilenames] = useState([])
  const [indexfilenames, setIndexFilenames] = useState([])
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [inputFileKey, setInputFileKey] = useState(0)
  const [retailerTab, setRetailerTab] = useState(false)
  const [csvRetailerFiles, setCsvRetailerFiles] = useState([])
  let interval;
  useEffect(() => {
    if (!userLoggedIn.loggedIn) {
      navigate('/login');
    }
    if (!userLoggedIn.curator) {
      navigate('/')
    }
    loadIndexingData();
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(loadIndexingData, 30000);

  }, []);



  const loadIndexingData = async () => {
    try {
      const response = await axios.get('/curator-api/get-status')
      setIndexFilenames(response.data.indexingFiles);
      setInsertFilenames(response.data.apifyFiles);
      setCsvRetailerFiles(response.data.csvRetailerFiles);
    } catch (e) {
      console.log(e)
    }
  }

  const startApifyButton = async () => {

    const response = await axios.post('/curator-api/start-apify-insert', {})
    if (response.data.successful) {
      Swal.fire("", "Inserting is in process", 'success');
    } else {
      Swal.fire("", "Please try again.", 'error');
    }


  }


  const startVisionIndexing = async () => {

    const response = await axios.post('/curator-api/start-indexing', {})
    if (response.data.successful) {
      Swal.fire("", "Indexing is in process", 'success');
    } else {
      Swal.fire("", "Please try again.", 'error');
    }


  }

  const uploadFiles = async () => {
    let fileInput = document.querySelector(retailerTab ? '#myFile2' : '#myFile1');
    if (!fileInput.files[0]) {
      Swal.fire("Please select a file", "", 'warning');
      return;
    }
    const formData = new FormData()
    formData.append('file', fileInput.files[0])
    setIsUploadingFile(true)
    const url = retailerTab ? '/curator-api/upload-retailer-files' : '/curator-api/upload-files'
    const response = await axios.post(url, formData)
    if (response.data.successful) {
      Swal.fire("Files uploaded successfully!", "Indexing is in process", 'success');
    } else {
      Swal.fire("Files uploading failed!", response.data.error, 'error');
    }
    setIsUploadingFile(false)
    setInputFileKey(prev => prev + 1)
  }


  return retailerTab ? <div className='w-full '>

    <div className='flex justify-end pr-4 pt-4'>
      <button className='primary-btn bg-green-600 hover:bg-green-700 active:bg-green-900' onClick={() => setRetailerTab(prev => !prev)}>Apify File</button>
    </div>
    <h1 className='text-center m-5 text-4xl font-semibold font-serif'>Retailer Products</h1>
    <div className='w-full'>
      <div className='flex flex-col w-2/5 m-auto rounded-lg p-10 shadow-[0_0_10px_1px_rgba(0,0,0,0.3)] bg-slate-200'>
        <input key={inputFileKey} type="file" id="myFile2" name="filename" className='m-4' accept='.csv' />
        <button className='font-bold bg-slate-700 w-60 py-2 px-8 rounded-lg text-white hover:bg-slate-600 disabled:bg-slate-300 my-4 mx-auto' disabled={isUploadingFile} onClick={uploadFiles}>UPLOAD FILE</button>
        <div className='flex justify-end'>
          <button className='bg-slate-400 text-white hover:bg-slate-500 px-2 rounded-lg' onClick={() => {
            const blob = new Blob([`url,imageUrl,title,productCode,price,description,retailer,brandName,gender
https://www.kalkifashion.com/in/beige-printed-kurta-and-pant-set.html,https://staticm247.kalkifashion.com/media/catalog/product/b/e/beige_printed_kurta_and_pant_set-sg179824_8_.jpg?w=800,Beige Printed Kurta And Pant Set,SG179824,₹4990,"Style No : SG179824Color: BeigeFabric: CrepeWork: EmbroideryClosure: NoneNeck Line: RoundSleeves: Full SleevesCan Can: NoPack Contains: 1 kurta, 1 pantManufactured / Packed by : Suarabhakti Goods Pvt ltdThere may be slight color variation depending on the screen one is looking through and once you receive the product.Inclusive of all taxesLend a fashionable vibe to your daily wear look with this casual and chic kurta set. Crafted in an ear More Product DetailsLend a fashionable vibe to your daily wear look with this casual and chic kurta set. Crafted in an earthy beige tone it boasts of stunning prints all over. Embellished with cutdana sequin and zari the kurta features a timeless round neckline and full sleeve design. Paired with pants don this attire for a comfortable fit. Gentle wash required. Avoid mothball use. Slight variation in color is possible due to digital photography. Being rewarded as the most trusted brand our customers too believe we deliver the same styles as promised on the website. View Less Product Details",Kalki Fashion,Kalki Fashion,Women
https://www.kalkifashion.com/in/black-floral-printed-crepe-kurta-and-pant-in-sequins-work.html,https://staticm247.kalkifashion.com/media/catalog/product/s/g/sg249607-black_floral_printed_crepe_kurta_and_pant_in_sequins_work_7_.jpg?w=800,Black Floral Printed Crepe Kurta And Pant In Sequins Work,SG249607,₹7299,Style No : SG249607Color: BlackFabric: CrepeWork: PrintClosure: NoneNeck Line: V-NecklineSleeves: Flared SleevesCan Can: NoPack Contains: 1 Kurta 1 PantManufactured / Packed by : Suarabhakti Goods Pvt ltdThere may be slight color variation depending on the screen one is looking through and once you receive the product.Inclusive of all taxesDance the night away in this mesmerizing black crepe kurta set. The blooming floral print adds a touch More Product DetailsDance the night away in this mesmerizing black crepe kurta set. The blooming floral print adds a touch of whimsy while shimmering sequins and delicate stone and bead embroidery create a captivating texture. Flared sleeves add a touch of drama making you the star of any soirée. The kurta is paired with a pant.Gentle wash required. Avoid mothball use. Slight variation in color is possible due to digital photography. Being rewarded as the most trusted brand our customers too believe we deliver the same styles as promised on the website. View Less Product Details,Kalki Fashion,Kalki Fashion,Women
https://www.kalkifashion.com/in/white-floral-printed-cotton-gown.html,https://staticm247.kalkifashion.com/media/catalog/product/w/h/white_floral_printed_cotton_gown-sg232037_7_.jpg?w=800,White Floral Printed Cotton Gown,SG232037,₹4879,"Style No : SG232037Color: WhiteFabric: Cotton silkWork: PrintClosure: Side ZipNeck Line: RoundSleeves: 3/4th SleeveCan Can: NoPack Contains: 1 GownManufactured / Packed by : Suarabhakti Goods Pvt ltdThere may be slight color variation depending on the screen one is looking through and once you receive the product.Inclusive of all taxesStep into a world of unparalleled luxury with our exquisite white gown. Created from premium cotton fa More Product DetailsStep into a world of unparalleled luxury with our exquisite white gown. Created from premium cotton fabric, this ensemble is ideal for both festive occasions and casual outings. Delicately embellished with intricate prints, thread work, sequins, and cut dana embroidery, it radiates elegance and sophistication.Gentle wash required.Avoid mothball use.Slight color variation is possible due to digital photography.Being rewarded as the most trusted brand our customers too believe we deliver the same styles as promised on the website. View Less Product Details",Kalki Fashion,Kalki Fashion,Women
https://www.kalkifashion.com/in/sky-blue-embroidered-silk-long-kurti.html,https://staticm247.kalkifashion.com/media/catalog/product/s/k/sky_blue_embroidered_silk_long_kurti-sg227424_6_.jpg?w=800,Sky Blue Embroidered Silk Long Kurti,SG227424,₹4879,Style No : SG227424Color: BlueFabric: Silk BlendWork: PrintClosure: Side ZipNeck Line: Shirt CollarSleeves: 3/4th SleeveCan Can: NoPack Contains: 1 Long KurtiManufactured / Packed by : Suarabhakti Goods Pvt ltdThere may be slight color variation depending on the screen one is looking through and once you receive the product.Inclusive of all taxesExperience sophistication with our sky blue long kurti meticulously tailored for festive occasions. Fa More Product DetailsExperience sophistication with our sky blue long kurti meticulously tailored for festive occasions. Fashioned from silk this elegant attire showcases intricate prints cut dana sequins.Gentle wash required. Avoid mothball use. Slight variation in color is possible due to digital photography. Being rewarded as the most trusted brand our customers too believe we deliver the same styles as promised on the website. View Less Product Details,Kalki Fashion,Kalki Fashion,Women
https://www.kalkifashion.com/in/green-resham-work-co-ord-set.html,https://staticm247.kalkifashion.com/media/catalog/product/g/r/green_resham_work_co-ord_set_-sg268926_6_.jpg?w=800,Green Resham Work Co-ord Set,SG268926,₹8950,Style No : SG268926Color: GreenFabric: OrganzaWork: Thread/ReshamClosure: Front Hook-EyeNeck Line: Mandarin CollarSleeves: 3/4th SleeveCan Can: NoPack Contains: 1 Kurta 1 Inner 1 PantManufactured / Packed by : Suarabhakti Goods Pvt ltdThere may be slight color variation depending on the screen one is looking through and once you receive the product.Inclusive of all taxesEmbrace the enchantment of this green co ord set. Its intricate resham embroidery delicate sequins and More Product DetailsEmbrace the enchantment of this green co ord set. Its intricate resham embroidery delicate sequins and captivating cut dana work create a mesmerizing masterpiece. The high low kurti with cut dana tassels adds a playful touch while the short length makes it look unique perfect for festive occasions. Gentle wash required. Avoid mothball use. Slight variation in color is possible due to digital photography. Being rewarded as the most trusted brand our customers too believe we deliver the same styles as promised on the website. View Less Product Details,Kalki Fashion,Kalki Fashion,Women`], { type: 'text/plain' });
            const a = document.createElement('a');
            a.setAttribute('download', 'sample-products.csv');
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.click();
          }}>Sample CSV</button>
        </div>
      </div>

    </div>

    <hr className='m-10 border-x-4' />
    <div className='m-[4rem]'>
      <h2 className='text-lg text-center font-semibold'>Retailer Products Insertion status</h2>
      <div className='bg-slate-200 border-2 my-5 flex flex-col border-slate-400'>
        {
          csvRetailerFiles.map((file, i) => <div key={i} className={`p-0 w-full border-b-2 border-slate-400 flex flex-row`}>
            <div className='w-2/3 m-0 overflow-x-auto p-4'>{file.originalname}</div>
            <div className='w-1/3'>{file.completedCount}/{file.productsCount}</div>
            <div className={`w-1/3 m-0 font-bold  ${file.complete ? 'bg-green-500 text-white' : 'bg-slate-600 text-white'} text-center pt-5 text-xl`}>{file.complete ? "Complete" : <div>In Progress <button className='hover:bg-slate-400 rounded-xl px-3 py-2 text-xs ' onClick={() => {
              axios.post(`/curator-api/restart-retailer-files?id=${file._id}`).then(res => {
                if (res.data.successful) {
                  Swal.fire({ title: "Insertion Started " })
                }
              })
            }}>Restart Insertion</button></div>}</div>
          </div>)
        }
      </div>
    </div>

    <hr className='m-10 border-x-4' />


  </div> : <div className='w-full m-0'>

    <div className='flex justify-end pr-4 pt-4'>
      <button className='primary-btn ' onClick={() => setRetailerTab(prev => !prev)}>Retailer File</button>
    </div>
    <h1 className='text-center m-5 text-4xl font-semibold font-serif'>APIFY Indexing</h1>
    <div className='w-full'>

      <div className='flex flex-col w-2/5 m-auto rounded-lg p-10 shadow-[0_0_10px_1px_rgba(0,0,0,0.3)] bg-slate-200'>
        <input key={inputFileKey} type="file" id="myFile1" name="filename" className='m-4' />
        <button className='font-bold bg-slate-700 w-60 py-2 px-8 rounded-lg text-white hover:bg-slate-600 disabled:bg-slate-300 my-4 mx-auto' disabled={isUploadingFile} onClick={uploadFiles}>UPLOAD FILES</button>
      </div>

    </div>
    <hr className='m-10 border-x-4' />
    <div className='m-[4rem]'>
      <h2 className='text-lg text-center font-semibold'>Apify Files status</h2>
      <div className='bg-slate-200 border-2 my-5 flex flex-col border-slate-400'>
        {
          insertfilenames.map((file, i) => <div key={i} className={`p-0 w-full border-b-2 border-slate-400 flex flex-row`}>
            <div className='w-2/3 m-0 overflow-x-auto p-4'>{file.originalname}</div>
            <div className='w-[2px] bg-white'></div>
            <div>{file.processedCount}/{file.totalNew}</div>
            <div className={`w-1/3 m-0 font-bold  ${file.status == 'complete' ? 'bg-green-500 text-white' : file.status ? 'bg-slate-600 text-white' : 'bg-slate-300'} text-center pt-5 text-xl`}>{file.status || "waiting"}</div>
          </div>)
        }
      </div>
      <div className='flex justify-center'>

        <button className='font-bold bg-green-600 disabled:bg-slate-400 w-60 py-2 px-8 rounded-lg text-white hover:bg-green-500 my-4 mx-auto' onClick={startApifyButton}>Start Inserting Data</button>
      </div>
    </div>

    <hr className='m-10 border-x-4' />

    <div className='m-[4rem]'>
      <h2 className='text-lg text-center font-semibold'>Indexing status</h2>
      <div className='bg-slate-200 border-2 my-5 flex flex-col  border-slate-400'>
        {
          indexfilenames.map((file, i) => <div key={i} className={`p-0 w-full border-b-2 border-slate-400 flex flex-row`}>
            <div className='w-2/3 m-0 overflow-x-auto p-4'>{file.name}</div>
            <div className='w-[2px] bg-white'></div>
            <div className={`w-1/3 m-0 font-bold  ${file.status == 'complete' ? 'bg-green-500 text-white' : file.status ? 'bg-slate-600 text-white' : 'bg-slate-300'} text-center pt-5 text-xl`}>{file.status || "waiting"}</div>
          </div>)
        }
      </div>
      <div className='flex justify-center'>

        <button className='font-bold bg-green-600 disabled:bg-slate-400 w-60 py-2 px-8 rounded-lg text-white hover:bg-green-500 my-4 mx-auto' onClick={startVisionIndexing}>Start Indexing</button>
      </div>
    </div>
  </div>

}

export default TrendDisplay

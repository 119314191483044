import axios from "axios";
import { useEffect, useState } from "react"
import ModalImage from "react-modal-image";
import Swal from "sweetalert2";
import Loadingwrapper from "../../component/LoadingWrapper"
import ImageTagsDetails from "../SocialTrendsTags/ImageTagsDetails";


const CombinationTrendPage = ({ type, trendId }) => {
  const [trendData, setTrendData] = useState(null)
  const [loading, setLoading] = useState(false);
  const [retailerImages, setRetailerImages] = useState([])
  const [socialImages, setSocialImages] = useState([])
  const [displaySocialImages, setDisplaySocialImages] = useState([])
  const [displayRetailerImages, setDisplayRetailerImages] = useState([])
  const getTrendData = async () => {
    setLoading(true)
    const resp = await axios.get(`/social-trends-v2-api/trend-page/${type}/${trendId}`);
    setTrendData(resp.data.trendData)
    setRetailerImages(resp.data.retailerImages)
    setSocialImages(resp.data.socialImages)
    setLoading(false)
  }

  const loadMore = () => {
    const currentLength = Math.max(displaySocialImages.length, displayRetailerImages.length)
    setDisplaySocialImages(socialImages.slice(0, currentLength + 120))
    setDisplayRetailerImages(retailerImages.slice(0, currentLength + 120))
  }

  useEffect(() => {
    loadMore()
  }, [socialImages, retailerImages])

  useEffect(() => {
    getTrendData()
  }, [type, trendId]);

  return <div>
    <Loadingwrapper loading={loading}>
      <div>{
        trendData ? <>
          <h2 className="text-xl font-semibold px-2 text-center ">{trendData.gender == 'M' ? 'Male' : 'Female'} {' > '}{trendData.category}{' > '}{trendData.tags[0].value}{' > '}{trendData.tags[1].value} </h2>
          <div className="flex justify-end px-3">
            <button className="rounded-xl primary-btn hover:bg-blue-700 active:bg-blue-800" onClick={() => {
              window.navigator.clipboard.writeText(`${window.location.origin}/v2-combination-trend/${type}/${trendId}`)
              Swal.fire({ title: 'URL copied to clipboard', timer: 800, icon: 'success' })
            }}>Copy Url</button>
          </div>
        </>
          : null
      }</div>

      <div className="flex ">
        <div className="w-1/2 shrink-0 border-r ">
          <h3 className="text-center my-3 font-medium">Social Images</h3>
          {socialImages.length > 0 ? <div className="flex flex-wrap">
            {
              displaySocialImages.map(img => <div key={img.mediaId} className='w-1/3 p-4 border h-72'>
                <ModalImage src={img.imageUrl} className=' h-40' small={img.imageUrl} medium={img.imageUrl} large={img.imageUrl} hideZoom={false} hideDownload={true} />
                <a href={`${img.postUrl}`} className='text-blue-600 font-medium' target='_blank'>{img.username}</a>
                <p>{new Date(img.timeStamp).toDateString()}</p>
                <ImageTagsDetails mediaId={img.mediaId} />
              </div>)
            }
          </div>
            : <div className="mt-16">
              <p>No Images </p>
            </div>}

        </div>

        <div className="w-1/2 shrink-0">

          <h3 className="text-center my-3 font-medium">Retailer Images</h3>
          {retailerImages.length > 0 ? <div className="flex flex-wrap">
            {
              displayRetailerImages.map(img => <div key={img.pk} className='w-1/3 p-4 border h-72 overflow-hidden'>
                <ModalImage src={img.image_url} className=' h-40' small={img.image_url} medium={img.image_url} large={img.image_url} hideZoom={false} hideDownload={true} />
                <a href={`${img.product_url}`} className="text-blue-600 w-full text-nowrap overflow-hidden text-[11px]" target='_blank'>{img.title}</a>
                <p>Brand : {img.brand}</p>
                <ImageTagsDetails mediaId={img.pk} isretailer={true} />
              </div>)
            }
          </div>
            : <div className="mt-16">
              <p>No Images </p>
            </div>}

        </div>
      </div>

      <div className="flex justify-center py-10">
        {
          Math.max(displayRetailerImages.length, displaySocialImages.length) < Math.max(socialImages.length, retailerImages.length) ?
            <button className="px-4 py-2 rounded-lg bg-blue-600 text-white" onClick={loadMore}>Load More</button>
            : <p>End of results</p>
        }
      </div>

    </Loadingwrapper>

  </div>
}

export default CombinationTrendPage 

import axios from "axios";
import { useEffect, useState } from "react"
import Select from "react-dropdown-select";
import { useSelector } from "react-redux";
import { acceptedTags, MajorColorArrays, ColorMapping, patterns, textures, FemaleTrendToStlCategory, MaleTrendToStlCategory, metaCategoryTags, valuesThumbnails } from '../../constants'
import { metaCategories, metaTags } from "../../constants";

function RunwayTagsUpdatePopup({ mediaId, isPage, setAllTagsFilled, allTagsFilled, type }) {

  const [imageData, setImageData] = useState(null)
  const { userLoggedIn } = useSelector(state => state)
  const [category, setCategory] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [categoriesUsed, setCategoriesUsed] = useState([])
  const [tag, setTag] = useState(null);
  const [values, setValues] = useState([]);
  const [categoryTags, setCategoryTags] = useState([])
  const [tagsRemaining, setTagsRemaining] = useState([])
  const [isCoordSelected, setIsCoordSelected] = useState(false)
  const [color, setColor] = useState('')
  const URL = type == 'web' ? '/images-meta-api/saved-image' : '/api/runway'

  const getImageData = () => {
    axios.get(`${URL}/image-data/${mediaId}`).then(response => {
      setImageData(response.data)
    })
  }


  useEffect(() => {
    if (!userLoggedIn.curator && categoriesUsed.length > 0) {
      setCategory(categoriesUsed[0])
    }
  }, [categoriesUsed])

  useEffect(() => {
    if (imageData && imageData.tagsArray) {
      const catsObj = {}
      imageData.tagsArray.forEach(c => {
        catsObj[c.category] = true
        const catsArraayTemp = metaCategories.filter(c => catsObj[c])
        setCategoriesUsed(catsArraayTemp)
      })
    } else {
      setCategoriesUsed([])
    }
  }, [imageData])

  useEffect(() => {
    if (!setAllTagsFilled) {
      return
    }
    if (!tagsRemaining || tagsRemaining.length == 0) {
      setAllTagsFilled(true)
    } else {
      setAllTagsFilled(false)
    }
  }, [tagsRemaining])

  const updateGender = (gender) => {
    axios.post(`${URL}/update-gender/${mediaId}`, { gender }).then(response => {
      getImageData()
    })
  }

  useEffect(() => {
    setValues(prev => {
      if (imageData && imageData.tagsArray) {
        const matchingValues = imageData.tagsArray.filter(el => el.category == category && el.tag == tag)
          .map(el => el.value)
        return matchingValues
      }
      return []
    })
  }, [tag, category, imageData])

  useEffect(() => {
    if (!imageData) {
      return
    }
    const tagsList = new Set(metaCategoryTags[category]);
    if (imageData.tagsArray) {
      imageData.tagsArray.forEach(t => {
        if (t.category == category) {
          tagsList.delete(t.tag)
        }
      })
    }
    setTagsRemaining([...tagsList.values()])
  }, [category, imageData])

  useEffect(() => {
    const tagsTemp = {}
    const isCoordObj = {}
    if (imageData && imageData.tagsArray) {
      imageData.tagsArray.forEach(r => {
        if (r.category == category) {
          if (!tagsTemp[r.tag])
            tagsTemp[r.tag] = []
          tagsTemp[r.tag].push(r.value)
          if (r.isCoord) {
            isCoordObj[r.tag] = true
          }
        }
      })
      const newTagsArray = []
      for (let k in tagsTemp) {
        newTagsArray.push({ tag: k, values: tagsTemp[k], isCoord: isCoordObj[k] })
      }
      setCategoryTags(newTagsArray)
    } else {
      setCategoryTags([])
    }
  }, [category, imageData])

  useEffect(() => {
    setTag(null)
  }, [category])

  useEffect(() => {
    if (!mediaId) return
    getImageData();
  }, [mediaId])

  useEffect(() => {
    if (errorMessage && category) {
      setErrorMessage(null)
    }
  }, [category])

  const addValues = () => {
    if (!category || category == "") {
      setErrorMessage("No Category Selected.")
      return
    }
    axios.post(`${URL}/add-values/${mediaId}`, { tag, values, category }).then(response => {
      getImageData()
    })
  }

  useEffect(() => {
    if (tag) {

      if (imageData && imageData.tagsArray) {
        const matchingValues = imageData.tagsArray.filter(el => el.category == category && el.tag == tag)
          .map(el => el.value)

        if (values[0] == matchingValues[0] && values[1] == matchingValues[1]) {
          return;
        }
      }
      if (values.length == 0 && !imageData.tagsArray)
        return;
      addValues()
    }
  }, [values])


  useEffect(() => {
    if (category != 'Coords') {
      setIsCoordSelected(false)
      return
    }

    const arr = imageData?.tagsArray?.filter(t => t.category == 'Coords' && t.tag == tag)
    if (arr.length == 0) {
      setIsCoordSelected(false)
      return
    }
    setIsCoordSelected(arr[0].isCoord)
  }, [tag])


  const updateIsCoord = (tag, category, isCoord) => {
    axios.post(`${URL}/coord/${mediaId}`, { tag, category, isCoord }).then(response => {
      getImageData()
    })
  }

  const flipCategoryCoord = (category) => {
    axios.post(`${URL}/flip-category-coord/${mediaId}`, { category }).then(response => {
      getImageData()
    })
  }

  return (
    <div className={`${isPage ? "w-full" : "w-[80vw] h-[78vh]"} overflow-auto flex flex-row flex-wrap ${userLoggedIn.curator ? '' : 'mt-8'}`}>
      {userLoggedIn.curator && <div className="w-full flex justify-end shrink-0 p-2 px-4 border-b border-b-slate-700 h-12">
        <div className="px-4 text-sm font-semibold ">{imageData ? imageData.curator : null}</div>
        <button onClick={() => {
          window.navigator.clipboard.writeText(`${window.location.origin}/product-meta-tags/${type == 'web' ? 'web' : 'runway'}/${mediaId}`)
        }} className="px-2 py-1 rounded bg-slate-600 active:bg-slate-700 hover:bg-slate-800 text-white text-sm">Copy Url</button>
      </div>}
      {imageData && <>
        <div className="w-1/2 shrink-0 border-r-2 border-slate-300">
          <img className="max-h-[50vh] mx-auto" src={imageData.image_url} />
          <div className="flex flex-col text-sm px-20">
            <p>Retailer Gender : {imageData.ecommerceGender}</p>
            <p>Title : {imageData.title}</p>
            <p>Product Type :{imageData.productType}</p>
            <a className="text-blue-600 hover:text-blue-700" href={imageData.product_url}>Product </a>

          </div>
          <hr />
        </div>
        <div className={`w-1/2 px-6 shrink-0 ${userLoggedIn.curator ? 'mb-[100vh]' : 'mb-10'}`}>
          {userLoggedIn.curator && <div className="flex w-full py-2 text-xs  mb-4 ">
            <button className={`rounded-l border-r-0 px-4 py-1 ml-4 font-semibold border-slate-400 hover:bg-slate-100 border  outline-none hover:shadow ${imageData.gender == 'M' ? 'bg-slate-600 text-white hover:bg-slate-800' : ""}`} disabled={imageData.gender == 'M'} onClick={() => { updateGender("M") }}>Male</button>
            <button className={`rounded-r border-l-0 px-4 py-1 mr-4 font-semibold border-slate-400 hover:bg-slate-100 border  outline-none hover:shadow ${imageData.gender == 'F' ? 'bg-slate-600 text-white hover:bg-slate-800' : ""}`} disabled={imageData.gender == 'F'} onClick={() => { updateGender("F") }}>Female</button>

            {
              imageData.gender &&
              <select id="update-tags-category" value={category} onChange={(e) => { setCategory(e.target.value) }} className='p-2'>
                <option value='' >--select subcategory--</option>
                {metaCategories.sort().map(cat => < option value={cat} >{cat}</option>)}
              </select>
            }
          </div>
          }
          <div className='mt-4'>
            <div className="flex  w-full overflow-x-auto border-b border-slate-500">
              {
                categoriesUsed.map((cat, i) => <button key={cat} className={`px-2 pt-1 py-1 rounded-t border-slate-300 ${cat == category ? 'bg-slate-700 text-white hover:bg-slate-900' : 'border border-slate-700 border-b-0 hover:bg-slate-50 bg-white'}`} onClick={() => setCategory(cat)}>{cat}</button>)
              }
            </div>
            <h3 className="text-2xl my-3 font-semibold text-center w-full ">{category}{userLoggedIn.curator && <button className={`px-2 py-1 border ml-1 text-sm rounded ${imageData && imageData.Coords && imageData.Coords.includes(category) ? 'border-slate-600 bg-slate-600 text-white' : 'border-black bg-white text-black'}`}
              onClick={() => {
                flipCategoryCoord(category);
              }}
            >{imageData && imageData.Coords && imageData.Coords.includes(category) ? 'Remove Coord' : 'Add Coord'}</button>
            }</h3>

            <div className="w-full h-full min-h-[300px]">
              <div className="">
                {categoryTags.sort((a, b) => a.tag - b.tag)
                  .filter(c => (userLoggedIn.curator || (c && c.values && c.values[0] != '#N/A'))).map((c, i) => <div key={c.tag} className="my-3">
                    <h3 className="font-semibold ">{i + 1}.{c.tag}{userLoggedIn.curator && <button className={`px-2 py-1 border text-xs rounded ${c.isCoord ? 'border-slate-600 bg-slate-600 text-white' : 'border-black bg-white text-black'}`} onClick={() => { updateIsCoord(c.tag, category, !c.isCoord) }}>{c.isCoord ? 'Remove Coord' : 'Add Coord'}</button>}</h3>
                    {(c.tag == 'COLOR' ? c.values.slice(0, 1) : c.values).map((v, j) => <p className="text-xs pl-4" key={j}>{j + 1}. {v}</p>)}
                  </div>)}
                {
                  categoryTags && categoryTags.filter(c => c.tag == 'COLOR')
                    .map(c => <div key={c.tag} className="my-3">
                      <h3 className="font-semibold ">{categoryTags.filter(c => (userLoggedIn.curator || (c && c.values && c.values[0] != '#N/A'))).length + 1}.MULTICOLOR</h3>
                      <p className="text-xs pl-4" key={0}>1. {c.values.join('/')}</p>
                    </div>)
                }
              </div>
            </div>
            <div className="flex flex-wrap">

              {
                userLoggedIn.curator && category &&
                <>
                  <h3>Select tag</h3>
                  <div className="w-full flex flex-wrap py-2">
                    <button className={`px-1 py-0.5 m-0.5 text-xs rounded border border-slate-600 shrink-0 group ${'COLOR' == tag ? 'text-white bg-slate-600' : tagsRemaining.includes('COLOR') ? 'text-black bg-red-200' : 'text-black bg-white'}`} onClick={() => setTag('COLOR')}
                    >COLOR</button>
                    {(metaCategoryTags[category] ? metaCategoryTags[category].sort() : []).map(t => <button className={`px-1 py-0.5 m-0.5 text-xs rounded border border-slate-600 shrink-0 group ${t == tag ? 'text-white bg-slate-600' : tagsRemaining.includes(t) ? 'text-black bg-red-200' : 'text-black bg-white'}`} onClick={() => setTag(t)}>{t}<div className="w-0 h-0 relative">
                    </div>
                    </button>)}

                  </div>
                </>
              }

              {tag &&
                <h3>{tag} values </h3>
              }
              <div className="w-full flex flex-wrap py-2">
                {
                  tag && tag != 'COLOR' &&
                  metaTags[tag].map(t => <button className={`px-1 py-0.5 m-0.5 group shrink-0 text-xs rounded border border-slate-600 ${values.includes(t) ? 'text-white bg-slate-600' : 'text-black bg-white'}`} onClick={() => setValues(prev => {
                    if (prev.includes(t)) {
                      return prev.filter(p => p != t)
                    }
                    if (prev.length >= 2) {
                      return [prev.pop(), t]
                    }
                    return [...prev, t]
                  })}>{t}<div className="w-0 h-0 ">
                      <div className="invisible fixed group-hover:visible h-80 w-[95vw] border border-slate-600 top-[2vh] left-10 bg-white opacity-100 py-2 border-b-8 border-b-slate-200">
                        {valuesThumbnails[tag] && valuesThumbnails[tag][t] ? <>
                          <h3 className="text-center h-1/5 font-semibold text-2xl pt-4">{valuesThumbnails[tag][t].description}</h3>
                          <img src={valuesThumbnails[tag][t].thumbnail} className="w-full h-4/5" />
                        </> : null}
                      </div> </div>
                  </button>)
                }
                {
                  tag &&
                  tag == 'COLOR' &&
                  <div className="">
                    <Select values={values.map(v => ({ value: v }))}
                      placeholder='Select Color'
                      onChange={next => {
                        if (next.length > 2) {
                          next = next.slice(next.length - 2)
                        }
                        setValues(prev => {
                          return next.map(v => v.value)
                        })
                      }}
                      multi={true}
                      labelField='value'
                      valueField='value'
                      className="w-40"
                      key={values.join('-')}
                      options={
                        Object.keys(MajorColorArrays).sort().map(c => ({ value: c }))
                      }
                    />
                  </div>
                }

              </div>
            </div>
          </div>

          {errorMessage && <p className="font-semibold text-red-400">{errorMessage}</p>}
        </div>
      </>}
    </div >
  )

}

export default RunwayTagsUpdatePopup

import { useParams } from "react-router-dom"
import TagsUpdatePopup from "../rawImage/RunwayTagsPopup";



const Trend = () => {

  const { mediaId, trendtype } = useParams()

  return <TagsUpdatePopup mediaId={mediaId} isPage={true} type={trendtype} />

}

export default Trend;

import { useSearchParams } from "react-router-dom"
import TrendImages from "../V2Leaderboard/TrendImages"

const V2LeaderboardImagesPage = () => {

  const [searchParams, setSearchParams] = useSearchParams()

  return <TrendImages
    trendType={searchParams.get('trends')}
    searchParams={searchParams}
    tag={searchParams.get('selectedtag')}
    value={searchParams.get('selectedvalue')}
    isPage={true}
  />
}

export default V2LeaderboardImagesPage

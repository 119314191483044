import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios'
import { useSelector } from 'react-redux';
import Popup from '../../component/Popup';
import Loadingwrapper from '../../component/LoadingWrapper';
import DesignRecipeImage from './DesignRecipeImage';
import { metaCategories, metaCategoriesMen } from '../../constants';
import Select from 'react-dropdown-select';

const DesignRecipeImages = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const limit = 80
  const [socialImages, setSocialImages] = useState([])
  const [socialCount, setSocialCount] = useState(0)
  const [retailerImages, setRetailerImages] = useState([])
  const [retailerCount, setRetailerCount] = useState(0)
  const [maxPage, setMaxPage] = useState(1);
  const [loading, setLoading] = useState(true)
  const [insertDatesOptions, setInsertDateOptions] = useState([])
  const [retailerOptions, setRetailerOptions] = useState([])
  const [SilhouetteOptions, setSilhouetteOptions] = useState([])
  const [socialSilhouetteCounts, setSocialSilhouetteCounts] = useState({})
  useEffect(() => {
    setSearchParams(prev => {
      prev.set('gender', prev.get('gender') || 'F')
      prev.set('category', prev.get('category') || 'Topwear')
      prev.set('tab', prev.get('tab') || 'both')
      prev.set('geography', prev.get('geography') || 'Asian,Western')
      prev.set('time', prev.get('time') || '15 days')
      return prev;
    })
  }, [])


  useEffect(() => {
    const tab = searchParams.get('tab')
    setMaxPage(tab == 'social' ? Math.ceil(socialCount / limit) : tab == 'retailer' ? Math.ceil(retailerCount / limit) : Math.ceil(Math.max(socialCount, retailerCount) / limit))
  }, [retailerCount, socialCount, searchParams.get('tab')])


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getImages(signal)
    return () => {
      // Cancel the request when the component unmounts
      controller.abort();
    };
  }, [searchParams.get('tab'), searchParams.get('page'), searchParams.get('geography'), searchParams.get('time'), searchParams.get('gender'), searchParams.get('category'), searchParams.get('silhouette'), searchParams.get('insertDate'), searchParams.get('retailer')])

  useEffect(() => {
    setSearchParams(prev => {
      prev.set('page', '1')
      return prev;
    })
  }, [searchParams.get('tab'), searchParams.get('geography'), searchParams.get('time'), searchParams.get('gender'), searchParams.get('category'), searchParams.get('silhouette'), searchParams.get('insertDate'), searchParams.get('retailer')])

  const getImages = (signal) => {
    setLoading(true)
    axios.get(`/social-trends-v2-api/design-recipe-images/${searchParams.get('tab') || 'social'}?gender=${searchParams.get('gender') || 'Female'}&category=${encodeURIComponent(searchParams.get('category'))}&geography=${searchParams.get('geography') || ''}&time=${searchParams.get('time') || ''}&page=${searchParams.get('page') || '1'}&retailer=${searchParams.get('retailer') || ''}&insertDate=${searchParams.get('insertDate') || ''}&silhouette=${searchParams.get('silhouette') || ''}&limit=${limit}`, { signal })
      .then(res => {
        setSocialImages(res.data.socialImages)
        setSocialCount(res.data.socialCount)
        setRetailerImages(res.data.retailerImages)
        setRetailerCount(res.data.retailerCount)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
      })

  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getRetailerFilterCounts(signal)
    return () => {
      // Cancel the request when the component unmounts
      controller.abort();
    };
  }, [searchParams.get('gender'), searchParams.get('category'), searchParams.get('silhouette'), searchParams.get('insertDate'), searchParams.get('retailer')])

  const getRetailerFilterCounts = (signal) => {
    axios.get(`/social-trends-v2-api/design-recipe-image-counts?gender=${searchParams.get('gender') || 'Female'}&category=${encodeURIComponent(searchParams.get('category'))}&&geography=${searchParams.get('geography') || ''}&time=${searchParams.get('time') || ''}&insertDate=${searchParams.get('insertDate') || ''}&retailer=${searchParams.get('retailer') || ''}&silhouette=${searchParams.get('silhouette') || ''}`, { signal })
      .then(res => {
        setRetailerOptions(res.data.retailerCounts.map(t => ({ ...t, label: `${t.retailer} (${t.count})` })))
        setInsertDateOptions(res.data.insertCounts.map(t => ({ ...t, label: `${t.insertDate} (${t.count})` })))
        const socialSilhouetteCounts = res.data.socialSilhouetteCounts
        const socialSilhouetteCountsObj = {}
        if (socialSilhouetteCounts) {
          socialSilhouetteCounts.forEach(c => {
            socialSilhouetteCountsObj[c.silhouette] = c.count
          })

        }
        setSocialSilhouetteCounts(socialSilhouetteCountsObj)
        setSilhouetteOptions(res.data.silhouetteCounts.map(t => ({ ...t, label: `${t.silhouette} (${socialSilhouetteCountsObj[t.silhouette] || 0}+${t.count})` })))
      })
      .catch(err => {
        console.error(err)
      })
  }

  return <div>
    <h2 className='text-center m-2 font-semibold text-lg'>{searchParams.get('category')} Product Leaderboard</h2>
    <div className='mx-5 max-[805px]:mx-0 mb-5 flex sticky top-12 mt-2 bg-white justify-between items-center z-10'>
      <div className='flex ' >
        {/*
        <select className='px-2 rounded py-1 border-slate-300 border m-1' value={searchParams.get('gender')} onChange={(e) => {
          setSearchParams(prev => {
            prev.set('gender', e.target.value);
            return prev;
          })
        }}>
          <option value={""} disabled>--Gender--</option>
          <option value={"F"}>Female</option>
          <option value={"M"}>Male</option>
        </select>
        <select className='px-2 rounded py-1 border-slate-300 border m-1' value={searchParams.get('category')} onChange={(e) => {
          setSearchParams(prev => {
            prev.set('category', e.target.value);
            return prev;
          })
        }}>
          <option value={""} disabled>--Category--</option>
          {(searchParams.get('gender') == 'M' ? metaCategoriesMen : metaCategories).map(cat => <option key={cat} value={cat}>{cat}</option>)}
        </select>
      */}
        <select className='px-2 rounded  border-slate-300 border m-1' value={searchParams.get('tab')} onChange={(e) => {
          setSearchParams(prev => {
            prev.set('tab', e.target.value)
            return prev
          })
        }}>
          <option value={''} disabled>--Image Source--</option>
          <option value={'both'}>Social + Retailer</option>
          <option value={'social'}>Social</option>
          <option value={'retailer'}>Retailer</option>
        </select>
        <div className='relative mx-1'>
          <Select
            key={'silhouette' + window.location.href}
            placeholder={'Silhouette'}
            options={[{ silhouette: 'All', label: 'All', count: SilhouetteOptions.reduce((prev, currentElem) => (prev + currentElem.count), 0) }, ...SilhouetteOptions]}
            valueField={'silhouette'}
            labelField={'label'}
            multi={true}
            values={SilhouetteOptions.filter((obj) => ((searchParams.get('silhouette') || '').split(',,').includes(obj.silhouette)))}
            onChange={(values) => setSearchParams(prev => {
              let next;
              if (values.length == 0 || values[values.length - 1].silhouette == 'All' || values.length == SilhouetteOptions.length) {
                next = ''
              } else {
                next = values.map(p => p.silhouette).join(',,')
              }
              prev.set('silhouette', next)
              return prev;
            })}
            searchable={false}
            className='px-2 rounded py-1 border-slate-300 border m-1 bg-slate-200 mr-2 w-80 flex flex-wrap text-black'

            wrapperClassName='m-2 w-40 text-black'
          />
        </div>
        {searchParams.get('tab') != 'retailer' ? <>
          <select className='px-2 rounded py-1 border-slate-300 border m-1' value={searchParams.get('geography')} onChange={(e) => {
            setSearchParams(prev => {
              prev.set('geography', e.target.value);
              return prev;
            })
          }}>
            <option value={"All"}>All geography</option>
            <option value={"Asian"}>Asian</option>
            <option value={"Indian"}>Indian</option>
            <option value={"Western"}>Western</option>
            <option value={"Asian,Western"}>Asian + Western</option>
          </select>
          <select className='px-2 rounded py-1 border-slate-300 border m-1' value={searchParams.get('time')} onChange={(e) => {
            setSearchParams(prev => {
              prev.set('time', e.target.value);
              return prev;
            })
          }}>
            <option value={""}>All Time</option>
            <option value={"7 days"}>7 days</option>
            <option value={"15 days"}>15 days</option>
            <option value={"30 days"}>30 days</option>
            <option value={"45 days"}>45 days</option>
            <option value={"60 days"}>60 days</option>
            <option value={"90 days"}>90 days</option>
            <option value={"180 days"}>180 days</option>
          </select>
        </> : null}
        {searchParams.get('tab') != 'social' ? <>
          <div className=' mx-1'>
            <Select
              key={'insertDate' + window.location.href}
              placeholder={'Insert Date'}
              options={[{ insertDate: 'All', label: 'All', count: insertDatesOptions.reduce((prev, currentElem) => (prev + currentElem.count), 0) }, ...insertDatesOptions]}
              valueField={'insertDate'}
              labelField={'label'}
              multi={true}
              values={insertDatesOptions.filter((obj) => ((searchParams.get('insertDate') || '').split(',,').includes(obj.insertDate)))}
              onChange={(values) => setSearchParams(prev => {
                let next;
                if (values.length == 0 || values[values.length - 1].insertDate == 'All' || values.length == insertDatesOptions.length) {
                  next = ''
                } else {
                  next = values.map(p => p.insertDate).join(',,')
                }
                prev.set('insertDate', next)
                return prev;
              })}
              searchable={false}
              className='px-2 rounded py-1 border-slate-300 border m-1 bg-slate-200 mr-2 w-80 flex flex-wrap'
              wrapperClassName='m-2 w-40'
            />
          </div>
          <div className=' mx-1'>
            <Select
              key={'retailer' + window.location.href}
              placeholder={'Retailer'}
              options={[{ retailer: 'All', label: 'All', count: insertDatesOptions.reduce((prev, currentElem) => (prev + currentElem.count), 0) }, ...retailerOptions]}
              valueField={'retailer'}
              labelField={'label'}
              multi={true}
              values={retailerOptions.filter((obj) => ((searchParams.get('retailer') || '').split(',,').includes(obj.retailer)))}
              onChange={(values) => setSearchParams(prev => {
                let next;
                if (values.length == 0 || values[values.length - 1].retailer == 'All' || values.length == retailerOptions.length) {
                  next = ''
                } else {
                  next = values.map(p => p.retailer).join(',,')
                }
                prev.set('retailer', next)
                return prev;
              })}
              searchable={false}
              className='px-2 rounded py-1 border-slate-300 border m-1 bg-slate-200 mr-2 w-80 flex flex-wrap'
              wrapperClassName='m-2 w-40'
            />
          </div>
        </> : null}
      </div>
      <div>
        <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 mr-2 rounded-3xl' disabled={parseInt(searchParams.get('page') || '1') <= 1}
          onClick={() => {
            setSearchParams(prev => {
              prev.set('page', parseInt(prev.get('page') || '1') - 1);
              return prev
            })
          }}>prev</button>
        <span className='mr-2'>{searchParams.get('page') || 1}/{maxPage}</span>
        <button className='hover:bg-blue-700 text-white disabled:opacity-50 bg-blue-500 px-7 py-1 mr-2 rounded-3xl' disabled={parseInt(searchParams.get('page') || '1') >= maxPage}
          onClick={() => {
            setSearchParams(prev => {
              prev.set('page', parseInt(prev.get('page') || '1') + 1);
              return prev
            })
          }}>next</button>
      </div>


    </div>



    <Loadingwrapper loading={loading}>
      <div className='flex w-screen'>
        {
          searchParams.get('tab') != 'retailer' ? <div className='w-full'>
            <div className='m-2 justify-center flex'><img src='/social-logo.png' className='h-5' /></div>
            <div className='flex w-full flex-wrap justify-center'>
              {
                socialImages.map(image => <DesignRecipeImage image={image} type={'social'} isBoth={searchParams.get('tab') == 'both'} />)
              }
            </div>

          </div> : null
        }
        {searchParams.get('tab') == 'both' ? <div className='w-0 border border-black'></div> : null}
        {
          searchParams.get('tab') != 'social' ? <div className='w-full'>
            <div className='m-2 justify-center flex'><img src='/retailer-logo.png' className='h-5' /></div>
            <div className='flex w-full flex-wrap justify-center'>
              {
                retailerImages.map(image => <DesignRecipeImage image={image} type={'retailer'} isBoth={searchParams.get('tab') == 'both'} />)
              }
            </div>

          </div> : null
        }

      </div>
    </Loadingwrapper>
    {loading ? <p className='text-center text-green-600 font-semibold text-sm mt-10'>Computing, Ranking...{searchParams.get('category') == 'Dresses' ? '(E.T. 2 minutes) ' : ''}Please wait</p> : null}
  </div >


}
export default DesignRecipeImages
